

<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import SupplierAutoComplete from '@/components/supplier-auto-complete-input.vue'

import Choices from "choices.js";


import {
    required,
} from "vuelidate/lib/validators";


import {searchExpense} from '@/api/misc'
import { getBrokerageApi } from "@/api/brokerage";
import {financial} from '@/api/misc'
import { getArApApi } from "@/api/cash";


export default {
    page: {
        title: "Create AP Expense ",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },

    validations: {
        expense: {
            expense: { required },
            due_date: { required },
            invoice :{required}
        },
    },


    data() {
        return {
            title: "Create Exepnse",
            items: [
                {
                    text: "AP List",
                    href: "/ap/list",
                },
                {
                    text: "Create Expense",
                    href: "/ap/list",
                },
            ],

            expense : {
                supplier_id : '',
                expense : 0,
                invoice : ''
            },

            dpconfig: {
                wrap: true,
                altInput: true,
                dateFormat: "Y-m",
                altFormat: "Y-m",
                ariaDateFormat: "Y-m",
            },
            dropzoneOptions: {
                url: "/AwsFileUpload/s3_upload_multi",
                thumbnailWidth: 150,
                
                maxFilesize: 50,
            },

            supplier_list: [],
            fee_choice: {},
            fee_list: [],
            office_choice: {},
            office_list: [],

            sub_office_choice : {},

            gl_entry_list : [],


            sub_office_code : '',
            sub_office_name : '',
            current_fee_code: '',
            is_split    : true,
            fee_amount : 0,
            fee_gst    : 0,
            fee_pst    : 0,
            total_amount : 0,
            gst_payable_flag : false,
            is_pst_enabled   : false,
            ap_supplier_gl_number_key: 'AP_SUPPLIER',
            gst_payable_key: 'GLOBAL_GST_PAID_GL_CODE',
            gl_config : {},

            show_error : false,
            is_pre_paid : false,
            balance_diff : 0,

            temp_grp_id  : 1000,

            show_expense_error : false,
            expense_error_message : '',
        }
    },

    components: {
        Layout,
        PageHeader,
        flatPickr,
        vueDropzone: vue2Dropzone,
        SupplierAutoComplete

    },

    methods: {

        f(v, d=2) {
            return financial(v, d)
        },


        formSubmit() {
            if (this.show_expense_error == true) {
                return;
            }
           
            this.$v.$touch();
           
            if (this.$v.$invalid == false) {

                let data = {
                    amount           : Number(this.total_amount),
                    supplier_id      : this.expense.supplier_id,
                    is_enable_gst    : this.gst_payable_flag == true ? 1 : 0,
                    payee_type       : 'SUPPLIER',
                    expenses         : this.gl_entry_list,
                    description      : this.expense.description,
                    invoice          : this.expense.invoice
                }
                if (this.expense.office_code) {
                    let office = this.office_list.find(e => e.value == this.expense.office_code)
                    data.office_id   = office.c.id
                    data.office_code = office.c.office_code

                    data.expenses.map(e => e.office_code = data.office_code)
                }

                getArApApi().create_expense(data).then((res) => {
                    if (res.errCode == 0) {
                        this.$alertify.message("Success " );
                        this.$router.push({name : 'ap-general'})
                    } else {
                    this.$alertify.error("Failed to Update " + res.errCode);
                    }
                })
            }
        },

        onSearchedFee(e) {
            searchExpense({"search_str" : e.detail.value}).then((res) =>{
                this.fee_list = []
                res.data.map((f) =>{
                    this.fee_list.push({
                        label : `${f.name} ${f.accounting_gl}`,
                        value : f.code,
                        fee : f
                    })
                })
                this.fee_choice.clearChoices()
                this.fee_choice.setChoices(this.fee_list)
                this.fee_choice.showDropdown()
            })
           
        },

        onAddFee() {

            let fee = this.fee_list.find((f) => f.value == this.current_fee_code)
            let amount = financial(-Number(this.fee_amount), 2) 
            let pst    = -this.fee_pst  
            let gst    = 0
            if ((fee.fee.TAX_FLAG == 1 || fee.fee.tax_flag == 1) && Number(this.fee_gst) != 0) {
                gst     =  -this.fee_gst
            }
            

            let office_code = this.is_split?this.sub_office_code: this.expense.office_code
            let office = this.office_list.find(e => e.value == office_code)

          
            this.gl_entry_list.push({
                fee_code     : fee.fee.code,
                gl_number    : fee.fee.accounting_gl,
                gl_label     : fee.fee.name + (office? ('-'+office.c.branch_name+'') : ''),
                amount       : amount,
                is_gst_gl    : false,
                is_base_flag : false,
                is_pst_gl    : false,
                office_code  : office_code,
                grp_id       : this.temp_grp_id
            })

            if (this.is_pst_enabled) {
                this.gl_entry_list.push({
                    fee_code     : fee.fee.code,
                    gl_number    : fee.fee.accounting_gl,
                    gl_label     : fee.fee.name + (office? ('-'+office.c.branch_name+'') : '') +('-PST' ),
                    amount       : pst,
                    is_gst_gl    : false,
                    is_pst_gl    : true,
                    is_base_flag : false,
                    office_code  : office_code,
                    grp_id       : this.temp_grp_id
                })
            }

            if ((fee.fee.TAX_FLAG == 1 || fee.fee.tax_flag == 1 ) && Number(gst) != 0) {
                this.gl_entry_list.push({
                    fee_code     : fee.fee.code,
                    gl_number    : fee.fee.accounting_gst_gl,
                    gl_label     : fee.fee.name+ (office? ('-'+office.c.branch_name+'') : ''),
                    amount       : gst,  
                    is_gst_gl    : true,
                    is_pst_gl    : false,
                    is_base_flag : false,
                    office_code  : office_code,
                    grp_id       : this.temp_grp_id
                })
            }

            this.temp_grp_id ++;

            this.balance_check()
        },

        onRemoveFee(fee) {
            this.gl_entry_list = this.gl_entry_list.filter(f =>  f.grp_id != fee.grp_id )
            this.balance_check()
        },



        onSupplierChanged(evt) {
            this.expense.supplier_id = evt.id
        },

        onUpdateAmount(gl_entry) {
            this.$alertify.prompt(
                "Update Sub Expense Amount",
                "0",
                (evt, value) => {
                    let entry = this.gl_entry_list.filter((e) => e.fee_code == gl_entry.fee_code && e.gl_number == gl_entry.gl_number)
                    entry[0].amount = value
                    this.balance_check()

                },
                () => {
                   
                }
            );
        },


        update_balance(new_value) {
            let gl_entry     = this.gl_entry_list.find((e) => e.is_base_flag ==  true )
            if (this.is_pre_paid) {
                gl_entry.amount = -Number(new_value)
            } else {
                gl_entry.amount = Number(new_value)
            }
            

            this.balance_check()
        },

        

        onFileUploaded(ev, resp) {
            console.log(ev)
           if (resp[0].errCode == 200) {
               this.expense.invoice_file = resp[0].response.url
           } 
       },


        onOfficeChanged(evt) {
           
            let office_obj =  this.office_list.find(e => e.value == evt.detail.value)

            this.sub_office_name = office_obj.c.name
        },

        balance_check() {
            this.balance_diff = this.f(Number(this.gl_entry_list.reduce((a, c) => a+Number(c.amount), 0)), 2)
            this.show_error = this.balance_diff != 0 ? true : false
        },

        onPrePaid() {
        
            let je_entry =  this.gl_entry_list.find(e => e.is_base_flag == true)
            if (this.is_pre_paid == true) {
                je_entry.gl_number =  '10019'
                je_entry.gl_label = 'General Account'
            } else {
                je_entry.gl_number =  this.gl_config[this.ap_supplier_gl_number_key]
                je_entry. gl_label = 'AP Supplier'
            }

            je_entry.amount = -Number(je_entry.amount)
            
        },

        queryInvoice() {
            if (!this.expense.invoice) {
                return
            }
            getArApApi().query_expense_by_invoice({invoice : this.expense.invoice}).then(res => {
                if (res.errCode != 3003) {
                    this.show_expense_error = true
                    this.expense_error_message = `${this.expense.invoice} already post`
                    
                } else {
                    this.show_expense_error = false
                }
                
            })
        }


    },

    watch: {
        total_amount  (new_value) {
           this.update_balance(new_value)
        },

        fee_amount (new_value) {
            this.fee_gst = financial(Number(new_value) * 0.05, 2)

            if (this.is_pst_enabled) {
                this.fee_pst = financial(Number(new_value) * 0.07, 2)
            }
        },

        is_pst_enabled(new_value) {
            if (new_value == true) {
                this.fee_pst = financial(Number(this.fee_amount) * 0.07, 2)
            } else {
                this.fee_pst = 0
            }
        }

        
    },


    created() {
        


        getBrokerageApi().officeApi.list({page : {page : 1, page_size : 50}}).then((res) => {
            if (res.errCode == 0 ) {
                this.office_list = []
                res.data.map((s) => {
                    this.office_list.push({
                        label : s.branch_name,
                        value : s.office_code,
                        c : s
                    })
                })

              //  this.office_choice.clearChoices()
               // this.office_choice.setChoices(this.office_list)

                this.sub_office_choice.clearChoices()
                this.sub_office_choice.setChoices(this.office_list)
            }
        })


        getBrokerageApi().configApi.gl_config().then(res => {
            if (res.errCode == 0) {
                this.gl_config = res.data
                this.gl_entry_list.push({gl_number : this.gl_config[this.ap_supplier_gl_number_key], gl_label : 'AP Supplier', amount :0, is_base_flag : true, fee_code :'000000'})
            }
        })
        


    },

    mounted() {
       

        // this.office_choice = new Choices('#choices-office-input', {
        //     placeholderValue: "This is a placeholder set in the config",
        //     searchPlaceholderValue: "search by name",
        // })


        this.fee_choice = new Choices('#choices-fee-input', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        })

        this.sub_office_choice = new Choices('#choices-sub-office-input')

    }
}
</script>


<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="card">
            <div class="card-body">
                <form  @submit.prevent="formSubmit">
                    <div class="row">

                        <div class="col-md-8">

                            <div class="row">
                                <div class="col-md-5">
                                    <div class="mb-3">

                                        <label for="choices-supplier-input" class="form-label">supplier</label>
                                        <SupplierAutoComplete :init_data="{placeholder: 'Search Supplier'}" :query_type="'BROKERAGE'" @onSelected="onSupplierChanged" />

                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="mb-3">
                                        <label >Total Amount</label>
                                        <input id="validationCustom01" v-model="total_amount" type="text" class="form-control" 
                                            placeholder="$" value="Mark" :class="{
                                                'is-invalid': $v.expense.expense.$error,
                                            }" />
                                        <div v-if="$v.expense.expense.$error" class="invalid-feedback">
                                            <span v-if="!$v.expense.expense.required">This value is required.</span>
                                        </div>
                                    </div>
                                </div>

                                
                                <div class="col-md-2">
                                    <div class="mb-3">
                                        <label  class="form-label">Pre Paid</label>
                                        <input type="checkbox" class="form-check mt-2"  @change="onPrePaid" v-model="is_pre_paid"/>
                                    </div>
                                </div>


<!--
                                <div class="col-md-2">
                                    <div class="mb-3">
                                        <label  class="form-label">Split Expense</label>
                                        <input type="checkbox" class="form-check mt-2" v-model="is_split"/>
                                    </div>
                                </div>

                            
                                <div class="col-md-3" :class="is_split?'d-none':'block'">
                                    <div class="mb-3">
                                        <label  class="form-label">Office</label>
                                        <select class="form-control" v-model="expense.office_code" name="choices-office-input"
                                            id="choices-office-input">
                                        </select>
                                    </div>
                                </div>
 -->
                            </div>
                            
                            <!-- end row-->

                            <div class="row">

                                <div class="col-md-3">
                                    <div class="mb-3">
                                        <label for="validationCustom03">Post Date</label>
                                        <flat-pickr v-model="expense.due_date" placeholder="Select a date" class="form-control"
                                            :class="{
                                                'is-invalid': $v.expense.due_date.$error,
                                            }"></flat-pickr>
                                        <div v-if="$v.expense.due_date.$error" class="invalid-feedback">
                                            <span v-if="!$v.expense.due_date.required">This value is required.</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="mb-3">
                                        <label for="validationCustom03">Invoice #</label>
                                        <input id="validationCustom01" v-model="expense.invoice" type="text" class="form-control"
                                            placeholder="" value="Mark" :class="{
                                                'is-invalid': $v.expense.invoice.$error,
                                            }" @blur="queryInvoice"/>
                                        <div v-if="$v.expense.invoice.$error" class="invalid-feedback">
                                            <span v-if="!$v.expense.invoice.required">This value is required.</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="validationCustom01">Description</label>
                                        <input id="validationCustom01" v-model="expense.description" type="text"
                                            class="form-control" placeholder="" value="" @keyup.enter.prevent="" />
                                    </div>
                                </div>

                            </div>
                            <!-- end row-->
                        </div>
                        <div class="col-md-4">
                            <vue-dropzone id="invoice_dropzone"  :use-custom-slot="true" :options="dropzoneOptions"   @vdropzone-success="onFileUploaded">
                                <div >
                                    <i class="display-4 text-muted "></i>
                                    <h4>Drop scanned invoice file to here.</h4>
                                </div>
                            </vue-dropzone>
                        </div>
                    </div>

                    <div class="row mt-4"></div>
                    <hr class="my-4" />

                    

                    <div class="row">
                        <div class="col-md-3">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">Expense Sub Type</label>
                                <select class="form-control" v-model="current_fee_code" name="choices-fee-input"
                                    @search="onSearchedFee" id="choices-fee-input">
                                </select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">Sub Amount</label>
                                <input type="text" v-model="fee_amount" class="form-control" placeholder="$" />
                            </div>
                        </div>
                        <div class="col-md-1">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">GST</label>
                                <input type="text" v-model="fee_gst" class="form-control" placeholder="$" />
                            </div>
                        </div>

                        <div class="col-md-1">
                            <div class="mb-3">
                                <label  class="form-label">PST?</label>
                                <input type="checkbox" class="form-check mt-2"  v-model="is_pst_enabled"/>
                            </div>
                        </div>

                        <div class="col-md-1">
                            <div class="mb-3">
                                <label for="choices-fee-input" class="form-label">PST</label>
                                <input type="text" v-model="fee_pst" class="form-control" placeholder="$"  :disabled="is_pst_enabled == false"/>
                            </div>
                        </div>

                        <div class="col-md-2" :class="is_split?'block':'d-none'">
                            <div class="mb-3">
                                <label  class="form-label">Office</label>
                                <select class="form-control" v-model="sub_office_code" name="choices-sub-office-input" @change="onOfficeChanged"
                                    id="choices-sub-office-input">
                                </select>
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="mb-3 mt-4">
                                <b-button variant="secondary" type="button" @click="onAddFee">Add Entry</b-button>
                            </div>
                        </div>

                    </div>
                    <!-- end row-->
                    <div class="row">
                        <div class="col-md-12">
                            <b-alert show variant="danger" v-if="show_expense_error">  {{expense_error_message}} </b-alert>
                        </div>
                    </div>
                     <!-- end row-->

                    <div class="row mt-5">
                        <b-tabs class="mt-n5" content-class="card card-body mt-3" nav-class="nav-tabs-custom" nav-wrapper-class="nav-tabs-custom">
                            
                            <b-tab title="Accounting">
                                <div class="table-responsive">
                                    <div class="table align-middle table-nowrap">
                                        <b-table :items="gl_entry_list" :fields="[{ key: 'GL' }, { key: 'Amount' },{key : 'Update'}, {key : 'Action'}]"
                                            responsive="sm" :per-page="gl_entry_list.length" class="table-check">

                                            <template #cell(GL)="data">
                                                {{ data.item.gl_number + '( '+data.item.gl_label+' )' }} 
                                            </template>
                                            <template #cell(Amount)="data">
                                                ${{ data.item.amount.toLocaleString() }}
                                            </template>
                                            <template #cell(Update)="data">
                                                <b-button variant="secondary" size="sm" @click="onUpdateAmount(data.item)" v-if="data.item.is_base_flag == false">
                                                    Update Amount
                                                </b-button>
                                            </template>
                                            <template #cell(Action)="data">
                                                <b-button variant="outline-danger" size="sm" @click="onRemoveFee(data.item)" v-if="data.item.is_base_flag == false">
                                                <i class="mdi mdi-close"></i>
                                                </b-button>
                                            </template>
                                        </b-table>
                                    </div>
                                </div>
                            </b-tab>
                       </b-tabs>
                       <!-- End Accounting Tab-->
                    </div>
                    <!-- end row-->

                    <div class="row">
                        <div class="col-md-12">
                            <b-alert show variant="danger" v-if="show_error">  Amount is not balanced, diff: {{`$${balance_diff.toLocaleString()}`}} </b-alert>
                        </div>
                    </div>
                     <!-- end row-->

                    <div class="row">
                        <div class="col-md-12">
                            <div class="d-flex flex-wrap gap-2 justify-content-center d-flex align-items-center">
                                <b-button variant="secondary" type="button" @click="$router.push({name : 'ap-general'})">Cancel</b-button>
                                <b-button variant="primary" type="button" @click="formSubmit">Submit</b-button>
                            </div>
                        </div>
                      

                    </div>
                    <!-- end row-->


                </form>
            </div>
        </div>
        <!-- end card -->
    </Layout>
</template>

<style scoped>
  #invoice_dropzone {
    letter-spacing: 0.2px;
    height: 150px;
  }
</style>